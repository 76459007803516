const config = {
  GRAPHQL_URL:
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    "https://hub-webservice.ceedow.world/graphql",
  REST_API:
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    "https://hub-webservice.ceedow.world",
  GRAPHQL_WS_URL:
    process.env.NEXT_PUBLIC_GRAPHQL_WS_URL ||
    "wss://hub-webservice.ceedow.world/graphql",
  recaptchaSiteKey: process.env.NEXT_RECAPTCHA_SITE_KEY,
  encrypt: {
    secret: process.env.NEXT_PUBLIC_SECRET || "wesh",
  },
  googleClient:
    process.env.NEXT_GOOGLE_CLIENT_ID ||
    "809861390112-26v7p7dvfms0g9m6746kvlpq10apmm4s.apps.googleusercontent.com",
  appUrl: process.env.NEXT_PUBLIC_SITE_URL || "https://lehub.online",
};
export default config;
