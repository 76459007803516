"use client";

import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  forwardRef,
  FC,
} from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type SnackbarContextType = {
  showSnackbar: (message: string, type?: AlertColor, duration?: number) => void;
  hideSnackbar: () => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

type SnackbarProviderProps = {
  children: ReactNode;
};

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<AlertColor>("info");
  const [duration, setDuration] = useState(3000);

  const showSnackbar = useCallback(
    (message: string, type: AlertColor = "info", duration = 3000) => {
      setMessage(message);
      setType(type);
      setDuration(duration);
      setOpen(true);
    },
    []
  );

  const hideSnackbar = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      <Snackbar
        draggable
        open={open}
        autoHideDuration={duration}
        onClose={hideSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        style={{ zIndex: 999999 }}
      >
        <Alert onClose={hideSnackbar} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
