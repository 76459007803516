import {
  decryptToken,
  encryptToken,
  encryptUserId,
  decryptUserId,
} from "./encryptUser";

export const setAuthToken = (token: string) => {
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.setItem("token", encryptToken(token));
  }
};

export const getAuthToken = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const token = localStorage.getItem("token");
    return token ? decryptToken(token) : "";
  }
  return "";
};

export const setUserId = (id: number) => {
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.setItem("id", encryptUserId(id.toString()));
  }
};

export const getUserId = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const id = localStorage.getItem("id");
    return id ? parseInt(decryptUserId(id)) : 0;
  }
  return 0;
};

export const removeAuthToken = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
  }
};
