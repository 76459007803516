"use client";

import Head from "next/head";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "@/theme";
import { IntlProvider } from "react-intl";
import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "@/config/config";
import { SnackbarProvider } from "@/context/SnackbarContext";
import { CountryProvider } from "@/context/CountryContext";
import { getAuthToken } from "@/utils/authToken";
import { ApolloWrapper } from "./ApolloWrapper";
import { ReactNode } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { fr } from "date-fns/locale/fr";
import "./page.module.css"

export default function Layout({ children }: { children: ReactNode }) {
  const token = getAuthToken();
  return (
    <html lang="fr">
      <Head>
        <meta name="apple-itunes-app" content="app-id=6477760847" />
      </Head>
      <body>
        <AppRouterCacheProvider options={{ enableCssLayer: true }}>
          <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
            <ApolloWrapper token={token}>
              <CssBaseline />
              <CountryProvider>
                <SnackbarProvider>
                  <GoogleOAuthProvider clientId={config.googleClient}>
                    <IntlProvider
                      defaultLocale="fr"
                      messages={{ hello: "hello" }}
                      locale={"fr"}
                    >
                      <ThemeProvider theme={theme}>
                        <main>{children}</main>
                      </ThemeProvider>
                    </IntlProvider>
                  </GoogleOAuthProvider>
                </SnackbarProvider>
              </CountryProvider>
            </ApolloWrapper>
          </LocalizationProvider>
        </AppRouterCacheProvider>
      </body>
    </html>
  );
}
