"use client";

import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  FC,
} from "react";

type CountryContextType = {
  selectCountry: (countryId: number) => void;
  countryId: number;
};

const CountryContext = createContext<CountryContextType>({
  countryId: 1,
  selectCountry() {},
});

export const useSelectCountry = (): CountryContextType => {
  const context = useContext(CountryContext);
  if (!context) {
    throw new Error("useSelectCountry must be used within a CountryProvider");
  }
  return context;
};

type CountryProviderProps = {
  children: ReactNode;
};

export const CountryProvider: FC<CountryProviderProps> = ({ children }) => {
  const [countryId, setCountryId] = useState(1);

  const selectCountry = useCallback((countryId: number) => {
    setCountryId(countryId);
  }, []);

  return (
    <CountryContext.Provider value={{ countryId, selectCountry }}>
      {children}
    </CountryContext.Provider>
  );
};
