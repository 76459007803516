import CryptoJS from "crypto-js";
import config from "../config/config";

export const encryptUserId = (userId: string) => {
  const encryptedUserId = CryptoJS.AES.encrypt(
    userId,
    config.encrypt.secret
  ).toString();
  return encryptedUserId;
};

export const encryptToken = (token: string) => {
  const encryptedUserToken = CryptoJS.AES.encrypt(
    token,
    config.encrypt.secret
  ).toString();
  return encryptedUserToken;
};

export const decryptToken = (token: string) => {
  const decryptUserToken = CryptoJS.AES.decrypt(token, config.encrypt.secret);
  const originalToken = decryptUserToken.toString(CryptoJS.enc.Utf8);
  return originalToken;
};

export const decryptUserId = (userId: string) => {
  const decryptUserID = CryptoJS.AES.decrypt(userId, config.encrypt.secret);
  const originaUserID = decryptUserID.toString(CryptoJS.enc.Utf8);
  return originaUserID;
};
